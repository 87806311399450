import { alpha, styled } from '@mui/material';
// ? Material Ui
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// ? Next js
import Image from 'next/image';
import React from 'react';
import { API } from '_helpers';

const Item = styled(Paper)(({ theme }) => ({
  '&:hover': {
    boxShadow: '0 15px 25px 0 rgba(0,0,0,0.1)',
    background: 'rgba(255, 255, 255, 0.1)',
  },
  padding: theme.spacing(4),
  borderRadius: 10,
}));

const Icon = styled(Stack)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

interface IWorkProcess {
  title: string;
  description: string;
  cards: ICards[];
}

const WorkProcess: React.FC<IProps<IWorkProcess>> = ({ data }) => {
  return (
    <Box
      sx={{
        flexGrow: 1,
        py: 6,
        bgcolor: (theme) =>
          theme.palette.mode === 'dark'
            ? `${alpha(theme.palette.action.active, 0.02)}`
            : theme.palette.grey.A200,
      }}
    >
      <Container>
        <Stack spacing={2} sx={{ px: { xs: 0, md: 12, lg: 20 } }}>
          <Typography
            component="h2"
            color="text.primary"
            variant="h2"
            textAlign="center"
            gutterBottom
          >
            {data.title}
          </Typography>
          <Typography color="text.secondary" textAlign="center" gutterBottom>
            {data.description}
          </Typography>
        </Stack>

        <Grid container spacing={4} sx={{ py: 8 }}>
          {data.cards.map((el) => (
            <Grid item xs={12} sm={6} md={3} key={el._id}>
              <Item elevation={1}>
                <Stack alignItems="center" justifyContent="center" spacing={2}>
                  <Paper
                    variant="outlined"
                    sx={{ p: 2, borderRadius: 6, mb: 4 }}
                  >
                    <Icon alignItems="center" justifyContent="center">
                      <Image
                        alt={el.media.alternativeText}
                        src={`${API}${el.media.url}`}
                        width={48}
                        height={48}
                      />
                    </Icon>
                  </Paper>
                  <Typography component="h1" variant="h3" gutterBottom>
                    {el.title}
                  </Typography>
                  <Typography
                    component="h1"
                    color="text.secondary"
                    textAlign="center"
                  >
                    {el.description}
                  </Typography>
                </Stack>
              </Item>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default WorkProcess;
